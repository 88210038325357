@charset "utf-8";

@import "minimal-mistakes/skins/dirt"; // skin
@import "minimal-mistakes"; // main partials

summary {
    margin: 0 0 1.3em
}

details[open] summary {
    font-weight: bold;
}
